import {PersonaOptions} from '@nlux/react';
import localImage from './exam_jam_square.jpg';

export const personas: PersonaOptions = {
    assistant: {
        name: 'ExamJam AI',
        avatar: localImage,
        tagline: 'Gives you tunes to ace your test',
    },
    user: {
        name: 'Me',
        avatar: localImage,
    }
};