import './App.css';
import App2 from './App2.tsx';

function App() {
  return (
      <App2 />
  );
}

export default App;
