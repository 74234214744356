import React, {useMemo} from 'react';
import {AiChat} from '@nlux/react';
import '@nlux/themes/nova.css';
import './custom-overrides.css';
import {streamAdapter} from './adapter';
import {personas} from './personas';

const App2 = () => {
    const adapter = useMemo(() => streamAdapter, []);
    return (
        <AiChat
            adapter={adapter}
            personaOptions={personas}
            displayOptions={{ colorScheme: 'dark' }}
        />
    );
};

export default App2;